
<script lang="ts" setup>


</script>
<template>
  <div class="loginArea">
    <div class="container">
      <div class="close">
        <a href="" @click.prevent="closeLogin">
          <font-awesome-icon :icon="['fa', 'close']" />
        </a>
      </div>
      <div class="loginBody">
        <h5>Login</h5>
        <span v-for="(item, index) in errorLogin" :key="index" class="errorMessage">
          {{ item.element }}
        </span>
        <div class="inputGoup">
          <span>
            <font-awesome-icon :icon="['fa', 'user']" />
          </span>
          <input v-model="data.email" type="email" placeholder="Email" class="inputRestritation">
        </div>
        <div class="inputGoup">
          <span>
            <font-awesome-icon :icon="['fa', 'user']" />
          </span>
          <input v-model="data.password" type="password" placeholder="Senha" class="inputRestritation">
        </div>
        <a href="" class="forgetPassword">Esquece minha senha</a>
        <a href="" @click.prevent="login" class="buttonRegistration">
          <span>Entrar </span>
        </a>
        <a href="" @click.prevent="openRegister" class="recoverPassword">Quero fazer meu
          <span>cadastro</span></a>
      </div>
    </div>
  </div>
</template>
