
<script>
export default {
    props:{
        description:{
            type: String 
        }
    }
}
</script>
<template>
    <a href="" @click.prevent>
        <span>{{description}}</span>
    </a>
</template>

<style lang="scss" scoped>
@import '../../assets/scss/detail.scss';

a{
        margin-block: 4px;
        background: #EFF1ED;
        border-radius: 10px;
        margin-right: 8px;
        padding: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-style: normal;
        width: auto;
        cursor: default;
        span{
            // font-family: 'Poppins';
            font-style: normal;
            font-weight: normal;
            font-weight: 600;
            font-size: 13px;
            color: #757874;
            align-items: center;
            text-align: center;

        }

    }
</style>