export default function convertTimer({hours, minutes, seconds}:{hours:number | any, minutes:number | any, seconds:number | any}){

    if(hours<10 && hours>0){
        hours = '0' + String(hours) +":";
    }else{ hours = ''; }

    if(minutes<10){
        minutes = '0' + String(minutes);
    }else if(minutes > 59){
        minutes = minutes - (Math.floor(minutes / 60) * 60);
    }

    if(seconds<10){
        seconds = '0' + String(seconds);
    }

    return String(hours) + String(minutes) + ':' + String(seconds);
}